<template>
  <div>
    <el-row>
      <el-col :span="24">
        <div class="accountCon-title">
          <span>{{type == 1 ? '进店总数': type == 2 ? '疤痕进店': type == 3 ? '青春痘进店' : type == 4 ? '成交总数': type == 5 ? '疤痕成交数': type == 6 ? '青春痘成交数': type == 7 ? '皮肤成交数' : `${query.realname}${query.source_name}成交数`}}详细信息</span>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-table
          border
          size="small"
          :data="list"
          :cell-style="$style.cellStyle"
          :header-cell-style="$style.rowClass"
        >
          <ElTableColumn label="客户姓名">
            <template slot-scope="{row}" >
              <div class="hand"  @click="goCustomerDetail(row)">{{ row.customer_name }}</div>
            </template>
          </ElTableColumn>
          <ElTableColumn label="客户手机号" prop="telephone">
          </ElTableColumn>
          <ElTableColumn label="性别" >
            <template slot-scope="{ row }">
              <span>{{row.sex == 1 ? '男': row.sex == 2 ? '女':'未知'}}</span>
            </template>
          </ElTableColumn>
          <ElTableColumn label="所属门店" prop="shop_name" />
          <ElTableColumn label="来源" prop="source_name" />
          <ElTableColumn label="疤痕类型" prop="scar_aggregate" />
          <ElTableColumn label="首次进店" prop="first_time"/>
          <ElTableColumn label="二次进店" prop="second_time" />
          <ElTableColumn label="客服" prop="operator_name" />
          <ElTableColumn label="护理师" prop="nurse_name" />
          <ElTableColumn label="成交时间" prop="cj_time" />
          <ElTableColumn label="成交金额" prop="cj_money" />
          <ElTableColumn label="录入时间" prop="create_time" />

        </el-table>
        <pagination
          class="page tc mt10"
          :total="page.total"
          :page.sync="page.page"
          :limit.sync="page.limit"
          @pagination="getList"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import pagination from "@/components/Pagination";
import { getMonthCustomerInfo } from "@/api/statistics";

class Page {
  total = 0;
  page = 1;
  limit = 10;
}
export default {
  name: "keepCustomerDetail",
  components: {
    pagination
  },
  data() {
    return {
      query: this.$route.query,
      type: this.$route.query.type,
      page: new Page(), // 分页
      list: [], // 数据展示
      phoneNumber: '',
      popperOptions: {
        eventsEnabled: true
      }
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    goCustomerDetail(row){
      let query = {
        id: row.id,
        activeName: "first",
        customer_name: row.customer_name,
        pageName: "客户列表",
      }
      let route = this.$router.resolve({ name: "客户详情", query:query });
      window.open(route.href, '_blank');
    },
    // 获取列表
    getList() {
      let query = this.$route.query;
      console.log(query);
      let params = {}
      switch (parseInt(query.type)) {
        case 1:
          params = {
            'action' : 'all_num',
            'all_num_ids':query.all_num_ids,
            'page' : this.page.page,
            'limit' : this.page.limit
          }
          break; 
        case 2:
          params = {
            'action' : 'bh_num',
            'all_bh_num_ids':query.all_bh_num_ids,
            'page' : this.page.page,
            'limit' : this.page.limit
          }
          break; 
        case 3:
          params = {
            'action' : 'dd_num',
            'all_dd_num_ids':query.all_dd_num_ids,
            'page' : this.page.page,
            'limit' : this.page.limit
          }
          break;
        case 4:
          params = {
            'action' : 'xm_num',
            'all_xm_num_ids':query.all_xm_num_ids,
            'page' : this.page.page,
            'limit' : this.page.limit
          }
          break;
          case 5:
          params = {
            'action' : 'all_deal',
            'all_deal_ids':query.all_deal_ids,
            'page' : this.page.page,
            'limit' : this.page.limit
          }
          break;
        case 6:
          params = {
            'action' : 'bh_deal',
            'all_bh_deal_ids':query.all_bh_deal_ids,
            'page' : this.page.page,
            'limit' : this.page.limit
          }
          break;
        case 7:
          params = {
            'action' : 'dd_deal',
            'all_dd_deal_ids':query.all_dd_deal_ids,
            'page' : this.page.page,
            'limit' : this.page.limit
          }
          break;
          case 8:
            params = {
              'action' : 'xm_deal',
              'all_xm_deal_ids':query.all_xm_deal_ids,
              'page' : this.page.page,
              'limit' : this.page.limit
            }
          break;
          case 9:
            params = {
              'action' : 'chengjiao',
              'chengjiao_ids':query.chengjiao_ids,
              'page' : this.page.page,
              'limit' : this.page.limit
            }
          break;
      }
      getMonthCustomerInfo({...params}).then(res => {
        this.list = res.data.data;
        this.page.total = res.data.total || 0;
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.accountCon-title{
  margin-bottom: 20px;
}
.hand {
  cursor: pointer;
  &:hover {
    color: #33bea7;
  }
}
.img {
  width: 225px;
  height: 225px;
}
</style>
